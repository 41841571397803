<template>
  <div>
    <div class="text-xl33 font-bold">
      {{ $t('navigation.settings') }}
    </div>

    <div class="mt-7-5 p-7-8 bg-background-primary security-container leading-tight md:p-4">
      <div>
        <div class="text-n-2xl font-semibold">
          {{ $t('security.twofa.title') }}
        </div>

        <div class="mt-4-7 text-n-md font-medium">
          {{ $t('security.twofa.description') }}
        </div>

        <div class="flex items-center mt-4-7">
          <img class="w-11-7 h-11-7 md:w-10 md:h-10" src="@/assets/icons/google_authenticator.png" />

          <div class="flex-1 ml-3-6 md:ml-2">
            <div class="text-n-2xl font-semibold">
              {{ $t('security.google_authenticator.name') }}
            </div>

            <div class="opacity-60 mt-n-sm text-n-md font-medium">
              {{ $t('security.google_authenticator.description') }}
            </div>
          </div>

          <div @click="handleOpenModal">
            <Toggle :checked="is2FAEnabled" :disabled="true" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="fixed w-screen h-screen bg-transparent-500 left-0 top-0 mt-17 z-50">
      <Loading version="v1" />
    </div>
  </div>
</template>

<script>
import TWO_FACTOR_AUTH_SETUP_STEP_ONE from '@/graphql/mutations/TwoFactorAuthSetupStepOne.gql';
import Toggle from '@/components/misc/Toggle/index.vue';
import { Loading } from '@/components/misc';

export default {
  name: 'TwoFactorAuthentication',
  components: {
    Toggle,
    Loading,
  },
  data() {
    return {
      isActive: false,
      isLoading: false,
    };
  },
  methods: {
    async handleOpenModal() {
      try {
        this.isLoading = true;

        // User doesn't have 2fa
        if (!this.is2FAEnabled) {
          await this.apolloApiCall({
            mutation: TWO_FACTOR_AUTH_SETUP_STEP_ONE,
          });

          return this.showModal('TwoFactorAuthenticationEmailVerification');
        }

        this.showModal('VerifyAuthentication');
      } catch (err) {
        await this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  computed: {
    is2FAEnabled() {
      return this.lodashGet(this.$store.state.api, 'current_user.user_info.twofa_enabled', false);
    },
  },
};
</script>

<style scoped>
.security-container {
  @apply border border-border shadow-card;
  border-radius: 25px;
}
</style>
