<template>
  <LayoutMainPages>
    <div class="flex">
      <!-- Left side -->
      <div :class="{ 'left-side': !isMobileDevice }" class="mr-14 w-full xl:mr-0">
        <TwoFactorAuthentication/>

        <GoToFeature
          @onClick="$router.push('/change-password')"
          class="mt-n-4xl"
          :title="$tc('password.change_password')"
          :subtitle="$tc('password.update_your_password_any_time')"
        />

        <GoToFeature
          @onClick="$router.push('/manage-sessions')"
          class="mt-n-4xl"
          :title="$tc('manage_sessions.title')"
          :subtitle="$tc('manage_sessions.subtitle')"
        />

        <GoToFeature
          @onClick="openUserDeleteAccountModal"
          class="mt-n-4xl text-text-negative"
          :title="$tc('user_delete.delete_account')"
        />
      </div>
    </div>
  </LayoutMainPages>
</template>

<script>
import { GoToFeature, LayoutMainPages } from '@/components/misc';
import TwoFactorAuthentication from '@/components/security/TwoFactorAuthentication';

export default {
  name: 'Security',
  components: {
    GoToFeature,
    LayoutMainPages,
    TwoFactorAuthentication,
  },

  methods: {
    openUserDeleteAccountModal() {
      this.showModal('DeleteAccountStepOneContactSupport');
    }
  },

  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'SECURITY_VIEWED',
    });
  },
};
</script>

<style scoped>
  .left-side {
    max-width: 606px;
  }
</style>
